import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'

import { 
  Button,
  LegacyCard,
  Layout,
  ButtonGroup,
  Tooltip,
  Icon,
  Box,
  Page,
  Spinner,
  Text,
  EmptyState,
} from "@shopify/polaris"
import { CalendarIcon } from "@shopify/polaris-icons";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { format, parse } from 'date-fns'
import OfferList from './list';
import FirebaseContext from '../../../providers/firebase';
import toOfferTypeCreate from './utils/navigateHandlers';
import calAnalyticsPerOffer from '../../../helpers/calAnalyticsPerOffer';
import getAnalytics from '../../../helpers/getAnalytics';
import { navigate } from 'gatsby';
import BFSAnnounceBanner from '../BFSAnnounceBanner';
import styled from 'styled-components';
const OffersPageHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  .Polaris-ButtonGroup {
    width: 100%;
    max-width: max-content;
    margin-left: 10px;
  }
  .feedback-banner-text div {
    margin-top: 10px;
  }
`

function Offers(props) {
  const { firebase, shop, token, host } = useContext(FirebaseContext);
  const [analytics, setAnalytics] = useState(null);
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const rulesDoc = doc(firebase.firestore, 'rules', shop);
  const [rules, rulesLoading, rulesError] = useDocumentData(
    rulesDoc,
    {
      transform: (snap) => snap.rules
    }
  );

  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc,
    {
      transform: (snap) => snap.shopData
    }
  );

  const domain = shopData && shopData.domain
  const locale = shopData && shopData.primary_locale || "en-US";
  const currency = shopData && shopData.currency || "USD";
  const today = new Date(new Date().setHours(0,0,0,0));
  const localDateRange = localStorage.getItem('upsellplus_dateRange') && JSON.parse(localStorage.getItem('upsellplus_dateRange')) || {}
  const chartDateRange =  localDateRange.startDate && localDateRange.endDate && !isNaN(new Date(localDateRange.startDate)) && !isNaN(new Date(localDateRange.endDate)) ? {startDate: parse(localDateRange.startDate, 'yyyy-MM-dd', new Date()), endDate: parse(localDateRange.endDate, 'yyyy-MM-dd', new Date())} : {startDate: new Date(new Date().setDate(today.getDate() - 30)), endDate: today}
  useEffect(() => {
    const run = async () => {
      const analyticsResult = await getAnalytics(token, shop, host, chartDateRange.startDate, chartDateRange.endDate)
      setAnalytics(calAnalyticsPerOffer(analyticsResult.data.data))
    }
    run()
  }, []);

  const handleGoToCheckout = useCallback(() => {
      window.open(`https://${domain}/admin/settings/checkout/editor`, '_blank')
  }, [domain]);

  return (
    (Array.isArray(rules)) || rulesLoading ?
    <Page fullWidth title='Upsell Offers'
    primaryAction={
      <Button  onClick={() => toOfferTypeCreate({state: rules})} variant="primary">
        New offer
      </Button>
    }
      secondaryActions={
        <ButtonGroup>
          <Tooltip content="This date range is adopted from your dashboard." dismissOnMouseOut>
            <Button>
              <div style={{display:'flex', alignItems: 'center',justifyContent: 'center', gap: '5px'}}>
              <Icon
                source={CalendarIcon}
                tone="base" style={{display:'inline-block'}}></Icon>
                  <span>{`${format(chartDateRange.startDate, 'M/d')} to ${format(chartDateRange.endDate, 'M/d')}`}</span>
              </div>
            </Button>
          </Tooltip>
        
          <Button onClick={handleGoToCheckout}>
          {'To Checkout Editor'}
          </Button>
        </ButtonGroup>
        }
    >
      <Layout>
          <Layout.Section>
            <BFSAnnounceBanner shop={shop}/>
            <Box paddingBlockEnd="800">
            <LegacyCard>
              { analytics ?
              
              <OfferList
                rules={rules} 
                locale={locale}
                currency={currency}
                analytics={analytics}
                chartDateRange={chartDateRange}
                {...props}
              />
              :
              <Box width='100%' padding="800">
                <Spinner accessibilityLabel="Spinner" />
              </Box>
              }
            </LegacyCard>
            </Box>
          </Layout.Section>
      </Layout>
    </Page>
    :
    <Page title='Upsell Offers'>
      <Box paddingBlockEnd="800">
      <LegacyCard sectioned>
      <EmptyState
        heading="Create Upsell Offer"
        action={{onAction: () => toOfferTypeCreate({state: rules}), content: 'Create Upsell Offer'}}
        secondaryAction={{
          content: 'To Checkout Editor',
          onAction: () => handleGoToCheckout()
        }}
        image="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/offer-empty-status.png?alt=media&token=c0ec7e50-27f2-4156-aa0b-0e9f30e36940"
        fullWidth
      >
        <p>Create an offer and grow your AOV</p>
      </EmptyState>
    </LegacyCard>
    </Box>
    </Page>
  );
}


export default Offers;