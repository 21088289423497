import React, { useState } from "react"
import { Banner, Text, Link, Box } from "@shopify/polaris"
const BFSAnnounceBanner = (props) => {
  const [bannerActive, setBannerActive] = useState((JSON.parse(localStorage.getItem('close_BFS_banner')) || []).includes(props.shop) ? false : true)
  const close_BFS_banner = () => {
    let storedStores = new Set(JSON.parse(localStorage.getItem('close_BFS_banner')) || []);
    storedStores.add(props.shop);
    localStorage.setItem('close_BFS_banner', JSON.stringify([...storedStores]));
    setBannerActive(false)
  }
  return (
      bannerActive ? 
        <Box paddingBlockEnd="400" width="100%">
            <Banner
            title="UpsellPlus is now Built for Shopify! 💎"
            status="success"
            onDismiss={() => {
                close_BFS_banner()
            }}
            >
                <Text variant="bodyMd" as="span">Our app has hit Shopify’s highest quality bar for safety, performance, and usefulness. &nbsp;
                    <Link
                    url="https://www.upsellplus.com/blog-post/upsellplus-is-built-for-shopify"
                    target="_blank"
                    monochrome
                    >
                    Learn more
                    </Link>
                </Text>
            </Banner>
        </Box>
        :
        false
  )
}

export default BFSAnnounceBanner
